import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import { ContentWithVerticalPadding } from "components/misc/Layouts";
import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";

import AnchorLink from "react-anchor-link-smooth-scroll";
import { Rnd } from "react-rnd";
import { motion } from "framer-motion";

import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as RadioIcon } from "feather-icons/dist/icons/radio.svg";
// import { ReactComponent as HandleIcon } from "images/handle-icon.svg";
// import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";

export const Row = tw.div`flex`;
export const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
// export const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
export const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0`;
export const PrimaryNavLinkButton = tw(
    NavLink
  )`text-gray-100 bg-primary-500 px-6 py-3 mr-0 border-none rounded hocus:bg-gray-400 focus:shadow-outline mt-6 md:mt-4 lg:mt-0`;
export const HeroRow = tw(Row)`flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 pb-16 max-w-screen-2xl mx-auto flex-wrap`;

export const Column = tw.div`flex-1`;

export const UpdateNotice = tw(Column)`w-full flex-auto mb-4 sm:mb-8 rounded px-4 py-3 sm:px-5 sm:py-4 bg-orange-100 text-orange-800 flex items-center sm:items-start md:items-center justify-center lg:justify-start border border-orange-200 text-xs sm:text-sm text-center sm:text-left md:leading-none`;
export const UpdateNoticeIcon = tw(RadioIcon)`w-0 sm:w-5 sm:mr-3`;

export const TextColumn = tw(Column)`mx-auto lg:mr-0 max-w-2xl lg:max-w-xl xl:max-w-2xl flex-shrink-0`;
export const Heading = tw(HeadingBase)`text-center lg:text-left text-primary-900 leading-snug`;
export const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-left lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;
export const Actions = tw.div`flex flex-col sm:flex-row justify-center lg:justify-start`;
export const ActionButton = tw(
  AnchorLink
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
export const PrimaryButton = tw(ActionButton)``;
export const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
export const FeatureList = tw.ul`mt-6 leading-loose flex flex-wrap max-w-xl mx-auto lg:mx-0`;
export const Feature = tw.li`mt-2 flex items-center flex-shrink-0 w-full sm:w-1/2 justify-center lg:justify-start`;
export const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
export const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
export const ImageColumn = tw(Column)`mx-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-8`;
export const ImageContainer = tw.div``;
export const Image = tw.img`max-w-full rounded-t sm:rounded`;

export const SectionContainer = tw(ContentWithVerticalPadding)``;
export const SectionHeading = tw(HeadingBase)`text-primary-900`;
export const SectionDescription = tw(DescriptionBase)`text-center mx-auto text-gray-600 max-w-4xl`;

export const PreviewCards = tw.div`flex flex-wrap -mr-12`;
export const PreviewCardContainer = tw.div`mt-24 mx-auto md:mx-0 max-w-lg w-full md:w-1/2 lg:w-1/3 pr-12`;
export const PreviewCard = tw(motion.a)`block rounded-lg shadow-raised`;
export const PreviewCardImageContainer = tw.div`rounded-t-lg border-0 border-b-0`;
export const PreviewCardImage = styled(motion.div)`
  ${props => css`background-image: url("${props.$imageSrc}");`}
  ${tw`h-128 md:h-144 bg-cover bg-left-top`}
`;
export const PreviewButton = tw(PrimaryButtonBase)`w-full rounded-b-lg rounded-t-none py-5 font-semibold`;

export const ComponentsContainer = tw.div`mt-24`;
export const ComponentsType = tw.h3`text-4xl font-black text-primary-500 border-b-4 border-primary-500 inline-block`;
export const Components = tw.div``;
export const Component = tw.div`mt-12 border rounded-lg bg-white`;
export const ComponentHeading = tw.div`px-8 py-5 border-b flex flex-col sm:flex-row justify-between items-center`;
export const ComponentName = tw.h6`text-lg`;
export const ComponentPreviewLink = tw.a`mt-4 sm:mt-0 text-primary-500 hocus:text-primary-900 transition duration-300 font-semibold flex items-center`;
export const ComponentContent = tw.div`flex justify-between overflow-hidden rounded-b-lg bg-gray-600 relative`;
export const ResizableBox = styled(Rnd)`
  ${tw`relative! bg-white pr-4`}
  .resizeHandleWrapper > div {
    ${tw`w-4! right-0!`}
  }
`;
export const ResizeHandleButton = tw.button`cursor-col-resize focus:outline-none w-4 border-l bg-gray-100 absolute right-0 inset-y-0`;

export const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

export const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
export const HighlightedText = tw.span`text-primary-500`;

export const mobileWindows = window.innerWidth < 765

export const navLinks = (
  <div style={{display: mobileWindows ? '' : 'flex', justifyContent: 'center'}}>
    <div style={{marginTop: mobileWindows ? 10 : 0}}>
      <NavLink href="/">
          Home
      </NavLink>
    </div>
    <div style={{marginTop: mobileWindows ? 10 : 0}}>
      <NavLink href="/info-kami">
          Info Kami
      </NavLink>
    </div>
    <div style={{marginTop: mobileWindows ? 10 : 0}}>
      <NavLink href="/produk-kami">
          Produk Kami
      </NavLink>
    </div>
    <div style={{marginTop: mobileWindows ? 10 : 0}}>
      <NavLink href="/galeri">
          Galeri
      </NavLink>
    </div>
    <div style={{marginTop: mobileWindows ? 20 : 0}}>
      <PrimaryNavLinkButton href="/kontak-kami">
          Hubungi Kami
      </PrimaryNavLinkButton>
    </div>
  </div>
)