import React, { useEffect, useState } from "react";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container } from "components/misc/Layouts";

import AboutUs from "./page/AboutUs";
import Footer from "./components/Footer";
import Information from "./page/Information";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import main from "../assets/promotion/main.jpeg"
import mainInfo from "../assets/promotion/main_info.jpeg"
import mainInfo2 from "../assets/promotion/main_info_2.jpeg"

import {
  HeroRow,
  TextColumn,
  Heading,
  Description,
  Image,
  StyledHeader,
  navLinks,
  mobileWindows,
} from 'components/componentMain';
import Gallery from "./page/Gallery";
import Products from "./page/Products";


const settings = {
  // dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  // slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '0px',
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1
      }
    }
  ]
};

function Main ({

}) {

    const [dimensions, setDimensions] = React.useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    const handleResize = () => {
      setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
      });
    }

    useEffect(() => {
      window.addEventListener("resize", handleResize, false);
    }, [])

    return(
        <AnimationRevealPage disabled>
            <StyledHeader fixed={true} links={navLinks} />
            <Container style={{padding: mobileWindows ? 32 : 64, paddingTop: mobileWindows ? 10 : 2}} tw="bg-gray-100 -mx-8 -mt-2 pt-2 px-8">
                <HeroRow>
                    <TextColumn>
                      <Description>{"Selamat Datang di"}</Description>
                      <Heading as="h3">
                          <span>{"Cemerlang"}</span>
                          <span style={{color: "#16253b"}}>{" Kurnia Alam"}</span>
                      </Heading>
                      <Description>{"Solusi tepat kebutuhan pakan ternak terbaik dan terpercaya"}</Description>
                      {
                        !mobileWindows && <AboutUs section={true}/>
                      }
                    </TextColumn>
                    {/* <ImageColumn>
                      <ImageContainer>
                        <Image src={heroScreenshotImageSrc} />
                      </ImageContainer>
                    </ImageColumn> */}
                    <TextColumn style={{width: mobileWindows ? '100%' : '50%', marginTop: mobileWindows ? 40 : 0}}>
                      <Slider {...settings}>
                        <div>
                          <Image src={main} />
                        </div>
                        <div>
                          <Image src={mainInfo} />
                        </div>
                        <div>
                          <Image src={mainInfo2} />
                        </div>
                      </Slider>
                    </TextColumn>
                </HeroRow>
                <Products section={true}/>
                <Information section={true}/>
                <Gallery section={true}/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
          
    )
}

export default Main