import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Slider from "react-slick";
import { motion } from "framer-motion";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { Container } from "components/misc/Layouts";

import {
  SectionContainer,
  SectionHeading,
  StyledHeader,
  navLinks
} from 'components/componentMain';
import Footer from "pages/components/Footer";

// gallery
const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;
const Header = tw(SectionHeading)``;
const DivContainer = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  // slidesToShow: 1,
  // slidesToScroll: 3,
  centerMode: true,
  centerPadding: '60px',
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1
      }
    }
  ]
};

function Gallery ({
    section
}) {

    let images = [
      "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/cemerlangkurniaalam/galeri/gallery_1.jpeg",
      "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/cemerlangkurniaalam/galeri/gallery_2.jpeg",
      "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/cemerlangkurniaalam/galeri/gallery_3.jpeg",
      "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/cemerlangkurniaalam/galeri/gallery_4.jpeg"
    ]

    return (
      <Container>
        {!section && <StyledHeader fixed={true} links={navLinks} />}
        <SectionContainer style={{paddingTop: section ? 0 : 120}}>
            <Header>Galeri</Header>
            <div style={{padding: 12}}/>
            <Slider {...settings}>
              {
                images.map((value, index) => {
                  return <div style={{padding: '10px'}}>
                    <img width={'auto'} height={'auto'} style={{padding: 10}} src={value} />
                  </div>
                })
              }
            </Slider>
        </SectionContainer>
        {!section && <Footer/> }
      </Container>
    )
}

export default Gallery