import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import MainInfo from "../../assets/promotion/main_info.jpeg";

import { contactUs } from "service/services";

import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';

import {
    HeroRow,
    // TextColumn,
    // Heading,
    // Description,
    // Image,
    StyledHeader,
    navLinks,
    mobileWindows
  } from 'components/componentMain';
import Footer from "pages/components/Footer";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

function ContactUs ({
    section,
    subheading = "Hubungi Kami",
    heading = <>Saya ingin tahu produk <span tw="text-primary-500">Cemerlang</span><wbr/> Kurnia Alam</>,
    description = "Punya pertanyaan mengenai produk kami ? Lengkapi form dibawah dan tim kami akan menghubungi anda.",
    submitButtonText = "Send",
    formAction = "#",
    formMethod = "get",
    textOnLeft = true,
}) {

  const productOptions = [
    {
      label: "Blood Meal",
      value: "garam",
    },
    {
      label: "Feather Meal (CFM)",
      value: "fm",
    },
    {
      label: "Meat Bone Meal (MBM)",
      value: "mbm",
    },
    {
      label: "Poultry Meat Meal (PMM)",
      value: "pmm",
    },
    {
      label: "Soybean Meal (SBM)",
      value: "sbm",
    },
    {
      label: "Garam",
      value: "garam",
    }
  ]

  const [ form, setForm ] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    company: "",
    about: productOptions[0],
    description: "",
    emailRegex: false,
  })

  const onChange = (e) => {
    console.log(e)
    if (e.email) {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(e.email)) {
            e = {
                ...e,
                emailRegex: true
            }
        } else {
            e = {
                ...e,
                emailRegex: false
            }
        }
    }
    if (e.phoneNumber) {
        const phoneRegex = /^[0-9+]*$/;
        if (phoneRegex.test(e.phoneNumber)) {
          e = {
            ...e
          }
        } else {
          return
        }
    }
    setForm(prev => ({
        ...prev,
        ...e
    }))
  }

  const sendForm = async () => {
    console.log(form)
    if (!form.name || !form.phoneNumber) {
        toast.error('Nama dan Nomor Telepon wajib diisi', {
            toastId: 'profileupdate-error'
        });
        return;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (form.email && !emailRegex.test(form.email)) {
        toast.error('Masukkan email dengan format yang valid', {
            toastId: 'profileupdate-error'
        });
        return;
    }
    let newForm = {
        ...form,
        about: form.about ? form.about.label : ''
    }
    let {data, error} = await contactUs(newForm)
    if ( error ) {
        toast.error('Terjadi kesalahan', {
            toastId: 'profileupdate-error'
        });
    } else {
        setForm({
            name: "",
            email: "",
            phoneNumber: "",
            company: "",
            about: productOptions[0],
            description: "",
        })
        toast.success('Tim kami akan segera menghubungi kamu', {
            toastId: 'contactus'
        });
    }
  }

  return (
    <Container style={{paddingLeft: 32, paddingRight: 32}}>
        {!section && <StyledHeader fixed={true} links={navLinks} /> }
        <Container style={{padding: mobileWindows ? 32 : 64, paddingTop: mobileWindows ? 10 : 0, 
            paddingRight: mobileWindows ? 32 : 128, paddingLeft: mobileWindows ? 32 : 128, 
            paddingBottom: mobileWindows ? 32 : 0}}>
            <TwoColumn>
                <ImageColumn>
                    <Image imageSrc={MainInfo}/>
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Heading>{heading}</Heading>
                        {description && <Description>{description}</Description>}
                        <Form action={formAction} method={formMethod}>
                          <div style={{display: mobileWindows ? '' : 'flex' }}>
                            <div style={{width: mobileWindows ? '100%' : '50%'}}>
                              <Input type="text" name="name" placeholder="Nama Saya" 
                              value={form.name}
                              style={{width: mobileWindows ? "100%" : ""}}
                              onChange={(e) => {
                                onChange({
                                  name: e.currentTarget.value
                                })
                              }} />
                            </div>
                            <div style={{width: mobileWindows ? '100%' : '50%'}}>
                              <Input type="email" name="email" placeholder="Email" 
                              value={form.email}
                              style={{width: mobileWindows ? "100%" : ""}}
                              onChange={(e) => {
                                onChange({
                                  email: e.currentTarget.value
                                })
                              }}/>
                              {
                                form.emailRegex && <span style={{color: 'red', fontSize: 12}}>Wrong email format</span>
                              }
                            </div>
                          </div>
                          <div style={{display: mobileWindows ? '' : 'flex'}}>
                            <div style={{width: mobileWindows < 765 ? '100%' : '50%'}}>
                              <Input type="text" name="company" placeholder="Perusahaan" 
                              value={form.company}
                              style={{width: mobileWindows ? "100%" : ""}}
                              onChange={(e) => {
                                onChange({
                                  company: e.currentTarget.value
                                })
                              }}/>
                            </div>
                            <div style={{width: mobileWindows < 765 ? '100%' : '50%'}}>
                              <Input type="text" name="text" placeholder="Nomor WA / HP" 
                              value={form.phoneNumber}
                              style={{width: mobileWindows ? "100%" : ""}}
                              onChange={(e) => {
                                onChange({
                                  phoneNumber: e.currentTarget.value
                                })
                              }}/>
                            </div>
                          </div>
                          <div style={{paddingTop: 18, fontSize: 14, fontWeight: 500, color: "#cbd5e1"}}>
                            Tanya Tentang
                          </div>
                          <div style={{display: 'flex'}}>
                            <div style={{width: '100%'}}>
                              <Select
                                  styles={{
                                      control: (styles) => ({ ...styles, fontSize: 14, color: "#e2e8f0", borderColor: "#e2e8f0"}),
                                      option: (styles, { data, isDisabled, isFocused, isSelected }) => ({ ...styles, fontSize: 14 }),
                                      placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#e2e8f0"}),
                                      singleValue: (styles, { data }) => ({ ...styles, fontSize: 14 }),
                                      input: (styles) => ({ ...styles, fontSize: 14, minHeight: 35 })
                                  }}
                                  options={productOptions}
                                  value={form.about}
                                  onChange={(e) => { onChange({about: e}) }}
                              />
                            </div>
                          </div>
                          <Textarea name="message" placeholder="Pesan kamu" 
                          value={form.description}
                          rows={1}
                          onChange={(e) => {
                              onChange({
                                description: e.currentTarget.value
                              })
                          }}/>
                        </Form>
                        <SubmitButton onClick={() => {
                            sendForm()
                        }}>{submitButtonText}</SubmitButton>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
            <ToastContainer
                toastStyle={{ color: '#024a88', fontSize: 12 }} 
                progressStyle={{ background: '#024a88' }}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
            />
        </Container>
        {!section && <Footer/> }
    </Container>
  );
};

export default ContactUs