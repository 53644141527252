import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Slider from "react-slick";
import { motion } from "framer-motion";
import { css } from "styled-components/macro"; //eslint-disable-line
// import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { Container } from "components/misc/Layouts";
import Footer from "pages/components/Footer";

import {
  Description,
  PrimaryButton,
  PrimaryNavLink,
  SectionContainer,
  SectionHeading,
  StyledHeader,
  mobileWindows,
  navLinks
} from 'components/componentMain';
import { NavLinks } from "components/headers/light";
// gallery

const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    // slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1
        }
      }
    ]
  };
                   
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;
const Header = tw(SectionHeading)``;
const DivContainer = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

function Products ({
    section
}) {

    const products = [
        {
          imageSrc:
            "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/cemerlangkurniaalam/products/product_bloodmeal.jpeg",
          title: "Blood Meal",
          // content: "Tomato Salad & Carrot",
          // price: "$5.99",
          // rating: "5.0",
          // reviews: "87",
          url: "#"
        },
        {
          imageSrc:
            "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/cemerlangkurniaalam/products/product_feathermeal.jpeg",
          title: "Feather Meal",
          // content: "Cheese Pizza",
          // price: "$2.99",
          // rating: "4.8",
          // reviews: "32",
          url: "#"
        },
        {
          imageSrc:
            "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/cemerlangkurniaalam/products/product_mbm.jpeg",
          title: "Meat Bone Meal (MBM)",
          // content: "Hamburger & Fries",
          // price: "$7.99",
          // rating: "4.9",
          // reviews: "89",
          url: "#"
        },
        {
          imageSrc:
            "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/cemerlangkurniaalam/products/product_pmm.jpeg",
          title: "Poultry Meat Meal (PMM)",
          // content: "Crispy Soyabeans",
          // price: "$8.99",
          // rating: "4.6",
          // reviews: "12",
          url: "#"
        },
        {
          imageSrc:
            "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/cemerlangkurniaalam/products/product_sbm.jpeg",
          title: "SOYBEAN MEAL (SBM)",
          // content: `Protein 45% \nFat 1.99% \nAsh 6.10%`,
          // price: "$7.99",
          // rating: "4.2",
          // reviews: "19",
          url: "#"
        },
        {
          imageSrc:
            "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/cemerlangkurniaalam/products/garamhalus.jpeg",
          title: "Garam Halus",
          content: `NaCl 98% \nNon Yodium`,
          // price: "$7.99",
          // rating: "4.2",
          // reviews: "19",
          url: "#"
        }
    ]

    return (
      <Container style={section ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 32, paddingRight: 32}}>
        {!section && <StyledHeader fixed={true} links={navLinks} />}
        <SectionContainer style={{paddingTop: section ? 0 : 120, paddingLeft: mobileWindows ? 16 : 64, paddingRight: mobileWindows ? 16 : 64}}>
            <Header>Produk Kami</Header>
            <DivContainer style={{display: 'flex', justifyContent: 'center'}}>
            {
                products.map((value, index) => {
                    if (section && index >= 4) {
                        return 
                    } else {
                        return (
                            <CardContainer key={index}>
                                <Card className="group" href={value.url} initial="rest" whileHover="hover" animate="rest">
                                    <CardImageContainer imageSrc={value.imageSrc}>
                                    {/* <CardRatingContainer>
                                        <CardRating>
                                        <StarIcon />
                                        {value.rating}
                                        </CardRating>
                                        <CardReview>({value.reviews})</CardReview>
                                    </CardRatingContainer> */}
                                    <CardHoverOverlay
                                        variants={{
                                        hover: {
                                            opacity: 1,
                                            height: "auto"
                                        },
                                        rest: {
                                            opacity: 0,
                                            height: 0
                                        }
                                        }}
                                        transition={{ duration: 0.3 }}
                                    >
                                      <div>
                                        {value.content && 
                                          <div style={{whiteSpace: 'pre-wrap', padding: 10, bottom: 0}}>
                                            Specification:
                                            <div style={{fontSize: 12}}>{value.content}</div>
                                          </div>
                                        }
                                        <CardButton onClick={() => {
                                            window.location.href = '/kontak-kami'
                                        }}>Kontak Kami</CardButton>
                                      </div>
                                    </CardHoverOverlay>
                                    </CardImageContainer>
                                    {/* {value.content && <div style={{fontSize: 12, whiteSpace: 'pre-wrap', padding: 10, bottom: 0}}>{value.content}</div>} */}
                                    {
                                      mobileWindows &&
                                      <CardText>
                                        {/* <CardTitle>{value.title}</CardTitle>
                                        <CardContent>{value.content}</CardContent>
                                        <CardPrice>{value.price}</CardPrice> */}
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                          {value.content && 
                                            <div style={{whiteSpace: 'pre-wrap', padding: 10, bottom: 0}}>
                                              Specification:
                                              <div style={{fontSize: 12}}>{value.content}</div>
                                            </div>
                                          }
                                          <CardButton onClick={() => {
                                              window.location.href = '/kontak-kami'
                                          }}><div>Kontak Kami</div></CardButton>
                                        </div>
                                      </CardText>
                                    }
                                </Card>
                            </CardContainer>
                        )
                    }
                })
            }
            </DivContainer>
            {section && 
              <div style={{paddingTop: 0, display: 'flex', justifyContent: 'center'}} onClick={() => {
                window.location.href = "/produk-kami"
              }} >
                <PrimaryButton style={{cursor: 'pointer'}}>Lihat Produk Lainnya</PrimaryButton>
              </div>
            }
        </SectionContainer>
       {!section &&  <Footer /> }
      </Container>
    )
}

export default Products