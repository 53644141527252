import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../assets/img/cka_icon.png";
import whatsapp from "../../assets/img/whatsapp.png";
import phonecall from "../../assets/img/phone-call.png";

import {
  navLinks,
} from 'components/componentMain';

const Container = tw.div`relative bg-gray-100 text-gray-900 -mx-8 -mb-8 px-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-500 hocus:border-gray-100 pb-1 transition duration-300`;
const SpanCemerlang = tw.span`text-primary-900`

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-16`;
const LogoText = tw.label`ml-2 font-black`;

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
const CompanyInfo = tw.div``


const Divider = tw.div`my-8 border-b-2 border-gray-800`
export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoImg src={LogoImage} />
              <LogoText><SpanCemerlang>Cemerlang</SpanCemerlang> Kurnia Alam</LogoText>
            </LogoContainer>
          </CompanyColumn>
          <Column>
            {/* <ColumnHeading>Location</ColumnHeading> */}
            <div style={{maxWidth: 300}}>
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1545.672687148696!2d106.96454342055571!3d-6.357907526136203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e699398d9fbf92b%3A0x45d86a115cca43b3!2sJl.%20San%20Fransisco%205%20Blok%20Q5%20No.25%2C%20Ciangsana%2C%20Kec.%20Gn.%20Putri%2C%20Kabupaten%20Bogor%2C%20Jawa%20Barat%2016968!5e0!3m2!1sen!2sid!4v1705977043534!5m2!1sen!2sid"
                    width="100%" 
                    height="300" 
                    style={{ border: 0 }}
                    allowFullScreen="" 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade"
                />
            </div>
          </Column>
          <Column>
            <CompanyAddress style={{padding: 10, textAlign: 'left'}}>
              Cluster Jl. San Fransisco 5 No.Q5/9, Ciangsana, Kec. Gn. Putri, Kabupaten Bogor, Jawa Barat 16968
            </CompanyAddress>
            {/* <ColumnHeading>Legal</ColumnHeading> */}
            {/* <LinkList>
              <LinkListItem>
                <Link href="#">GDPR</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Privacy Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Terms of Service</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Disclaimer</Link>
              </LinkListItem>
            </LinkList> */}
          </Column>
          <Column>
            <ColumnHeading>Quick Links</ColumnHeading>
            <LinkList>
              {/* {
                navLinks.map( value => {
                  return <LinkListItem>
                    <Link href="#">Blog</Link>
                  </LinkListItem>
                })
              } */}
              <LinkListItem>
                <Link href="/">Home</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/info-kami">Info Kami</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/produk-kami">Produk Kami</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/galeri">Galeri</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/kontak-kami">Hubungi Kami</Link>
              </LinkListItem>
              <LinkListItem>
                <span>085175216359</span> - Sri
                <div style={{display: 'flex'}}>
                  <img width={20} height={20} src={whatsapp}/>
                  <img width={20} height={20} style={{marginLeft: 2, padding: 1.5}} src={phonecall}/>
                </div>
              </LinkListItem>
              <LinkListItem>
                <span>081270204011</span> - Hendi
                <div style={{display: 'flex'}}>
                  <img width={20} height={20} src={whatsapp}/>
                  <img width={20} height={20} style={{marginLeft: 2, padding: 1.5}} src={phonecall}/>
                </div>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="#">Sales</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Report Abuse</Link>
              </LinkListItem> */}
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider/>
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>&copy; Copyright {new Date().getFullYear()}, CV Cemerlang Kurnia Alam by Wuebuild.</CopyrightNotice>
          <CompanyInfo></CompanyInfo>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
