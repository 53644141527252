import React from "react"
import { SectionHeading, Subheading } from "components/misc/Headings"

import { Container } from "components/misc/Layouts";
import styled from "styled-components";
import tw from "twin.macro";
import Features from "components/features/ThreeColWithSideImage.js";
import StatsColumnsCKA from "pages/components/StatsColumnsCKA";

import {
    SectionContainer,
    SectionDescription,
    HighlightedText,
    Row,
    Column,
    TextColumn,
    StyledHeader,
    mobileWindows,
    navLinks
  } from 'components/componentMain';
import AboutUs from "./AboutUs";
import TwoSingleColumnsCKA from "pages/components/TwoSingleColumnsCKA";
import Footer from "pages/components/Footer";

const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;
const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

function Information ({
    section
}) {
    
    return (
        <Container style={{paddingTop: section ? 0 : 64, paddingLeft: section ? 0 : 32, paddingRight: section ? 0 : 32}}>
            {!section && <StyledHeader fixed={true} links={navLinks} />}
            <SectionContainer style={mobileWindows ? {paddingLeft: 16, paddingRight: 16} : {paddingLeft: 128, paddingRight: 128}}>
                {
                    mobileWindows &&
                    // <Features
                    //     subheading={<Subheading></Subheading>}
                    //     heading={
                    //         <>
                    //             Tentang <HighlightedText> Cemerlang</HighlightedText> Kurnia Alam
                    //         </>
                    //     }
                    //     description="Merupakan perusahaan importir langsung tanpa perantara. Cemerlang Kurnia Alam tetap konsisten berdiri sendiri tanpa campur tangan perusahaan atau bergabung dengan group lain agar dapat menyediakan produk pakan dengan kualitas terbaik sejak 2021."
                    //     cards={[]}
                    // />
                    <div>
                        <Subheading></Subheading>
                        <Heading>Tentang <HighlightedText> Cemerlang</HighlightedText> Kurnia Alam</Heading>
                        <Description>{"Merupakan perusahaan importir langsung tanpa perantara. Cemerlang Kurnia Alam tetap konsisten berdiri sendiri tanpa campur tangan perusahaan atau bergabung dengan group lain agar dapat menyediakan produk pakan dengan kualitas terbaik sejak 2021."}</Description>
                    </div>
                }
                {
                    !mobileWindows &&
                    <Features
                        subheading={<Subheading></Subheading>}
                        heading={
                            <>
                                Tentang <HighlightedText> Cemerlang</HighlightedText> Kurnia Alam
                            </>
                        }
                        description="Merupakan perusahaan importir langsung tanpa perantara. Cemerlang Kurnia Alam tetap konsisten berdiri sendiri tanpa campur tangan perusahaan atau bergabung dengan group lain agar dapat menyediakan produk pakan dengan kualitas terbaik sejak 2021."
                        cards={[]}
                    />
                }
                {/* <SectionHeading>Landing Pages</SectionHeading> */}
                <TwoSingleColumnsCKA
                    column1={
                        !section &&
                        <AboutUs />
                    }
                    column2={
                        <Card>
                            <Row>
                                {/* <div style={{maxWidth: 300}}>
                                    <iframe 
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1545.672687148696!2d106.96454342055571!3d-6.357907526136203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e699398d9fbf92b%3A0x45d86a115cca43b3!2sJl.%20San%20Fransisco%205%20Blok%20Q5%20No.25%2C%20Ciangsana%2C%20Kec.%20Gn.%20Putri%2C%20Kabupaten%20Bogor%2C%20Jawa%20Barat%2016968!5e0!3m2!1sen!2sid!4v1705977043534!5m2!1sen!2sid"
                                        width="100%" 
                                        height="300" 
                                        style={{ border: 0 }}
                                        allowFullScreen="" 
                                        loading="lazy" 
                                        referrerPolicy="no-referrer-when-downgrade"
                                    />
                                </div>
                                <div style={{marginLeft: 10}}>
                                    <div></div>
                                </div> */}
                            </Row>
                        </Card>
                    }
                />
                {/* <Features
                    subheading={<Subheading></Subheading>}
                    heading={
                    <>
                        Pelanggan Setia Kami
                    </>
                    }
                    description={""}
                    cards={[]}
                /> */}
                {/* <StatsColumnsCKA/> */}
                {/* <SectionDescription>
                    Lokasi
                </SectionDescription> */}
            </SectionContainer>
            { !section && <Footer/> }
        </Container>
    )
}

export default Information